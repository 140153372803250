export const signInUserData = [
	{
		id: '21',
		password: '123Qwe',
		accountUserName: 'admin',
		userName: 'Jorge Saurina',
		email: 'soporte@psa.mx',
		avatar: '/img/avatars/thumb-1.jpg',
		authority: ['admin', 'user'],
		isApproved: false,
		setup: 'pending',
	},
];

import BaseService from './BaseService';

const api = {
	fetchData(param) {
		return new Promise((resolve, reject) => {
			BaseService(param)
				.then(response => {
					resolve(response.data);
				})
				.catch(errors => {
					reject(errors);
				});
		});
	},
};

export default api;

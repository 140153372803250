const appConfig = {
	apiPrefix: 'https://core.psa.mx/',
	//apiPrefix: 'http://localhost:8000/',
	authenticatedPath: '/dashboard',
	authenticatedEntryPath: '/setup/welcome',
	unAuthenticatedEntryPath: '/sign-in',
	tourPath: '/app/account/kyc-form',
	locale: 'es',
	enableMock: false,
};

export default appConfig;

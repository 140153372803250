import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import appConfig from 'configs/app.config';

import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { ADMIN } from 'constants/roles.constant';

import { setUser, userLoggedOut } from 'store/auth/userSlice';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';

import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService';

import useQuery from './useQuery';

function useAuth() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const query = useQuery();

	const { token, signedIn } = useSelector(state => state.auth.session);

	const { isApproved, setup } = useSelector(state => state.auth.user);

	const signIn = async values => {
		try {
			const response = await apiSignIn(values);

			if (response.data) {
				const { user, token } = response.data;

				dispatch(onSignInSuccess({ token }));

				if (user) {
					dispatch(setUser(user));

					const redirectUrl = query.get(REDIRECT_URL_KEY);

					if (user.authority.includes(ADMIN)) {
						navigate(redirectUrl ? redirectUrl : appConfig.authenticatedPath);
					} else {
						if (user.isApproved && user.setup === 'complete') {
							navigate(redirectUrl ? redirectUrl : appConfig.authenticatedPath);
						} else {
							navigate(appConfig.authenticatedEntryPath);
						}
					}
				}

				return {
					status: 'success',
					message: '',
				};
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const signUp = async values => {
		try {
			const response = await apiSignUp(values);

			if (response.status) {
				const { user, token } = response.data;

				dispatch(onSignInSuccess(token));

				if (user) {
					dispatch(setUser(user));
				}

				navigate(`${appConfig.authenticatedEntryPath}`);

				return {
					status: 'success',
					message: '',
				};
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	const handleSignOut = () => {
		dispatch(onSignOutSuccess());
		dispatch(userLoggedOut());

		navigate(appConfig.unAuthenticatedEntryPath);
	};

	const signOut = async () => {
		try {
			const response = await apiSignOut();

			if (response.status) handleSignOut();
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString(),
			};
		}
	};

	return {
		authenticated: token && signedIn,
		isApproved,
		setup,
		signIn,
		signUp,
		signOut,
	};
}

export default useAuth;

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	id: null,
	userName: '',
	name: '',
	avatar: '',
	email: '',
	authority: [],
	emailVerified: '',
};

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.id = action.payload.id || state.id;
			state.userName = action.payload.userName || state.userName;
			state.name = action.payload.name || state.name;
			state.avatar = action.payload.avatar || state.avatar;
			state.email = action.payload.email || state.email;
			state.isApproved = action.payload.isApproved || state.isApproved;
			state.setup = action.payload.setup || state.setup;
			state.authority = action.payload.authority || state.authority;
			state.emailVerified = action.payload.emailVerified || state.emailVerified;
		},
		userLoggedOut: () => initialState,
	},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;

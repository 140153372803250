import React from 'react';
import classNames from 'classnames';

import { FormItem } from 'components/ui';

const FormRow = props => {
	const {
		label,
		children,
		errors,
		touched,
		name,
		asterisk = false,
		border = true,
		alignCenter = true,
		isBoolean = false,
	} = props;

	return (
		<div
			className={classNames(
				'grid md:grid-cols-3 gap-4 py-5',
				border && 'border-b border-gray-200 dark:border-gray-600',
				alignCenter && 'items-center',
			)}>
			<div className="font-semibold">
				{asterisk && <span className="text-red-500 ltr:mr-1 rtl:ml-1">*</span>}
				{label}
			</div>
			<div className="col-span-2">
				{isBoolean ? (
					<FormItem
						className="mb-0 max-w-[700px]"
						invalid={Boolean(errors[name] && touched[name])}
						errorMessage={errors[name]}>
						{children}
					</FormItem>
				) : (
					<FormItem
						className="mb-0 max-w-[700px]"
						invalid={errors[name] && touched[name]}
						errorMessage={errors[name]}>
						{children}
					</FormItem>
				)}
			</div>
		</div>
	);
};

export default FormRow;

import api from './ApiService';

export async function apiCsrfCookie(data) {
	return api.fetchData({
		url: '/sanctum/csrf-cookie',
		method: 'get',
	});
}

export async function apiSignIn(data) {
	return api.fetchData({
		url: '/api/auth/login',
		method: 'post',
		data,
	});
}

export async function apiSignUp(data) {
	return api.fetchData({
		url: '/api/auth/register',
		method: 'post',
		data,
	});
}

export async function apiSignOut(data) {
	return api.fetchData({
		url: '/api/auth/logout',
		method: 'post',
		data,
	});
}

export async function apiVerifyEmail(data) {
	return api.fetchData({
		url: `/api/email/verify/${data.id}/${data.hash}?expires=${data.expires}&signature=${data.signature}`,
		method: 'get',
	});
}

export async function apiResendLinkVerification(data) {
	return api.fetchData({
		url: '/api/email/verify/resend',
		method: 'post',
		data,
	});
}

export async function apiForgotPassword(data) {
	return api.fetchData({
		url: '/api/auth/forgot-password',
		method: 'post',
		data,
	});
}

export async function apiResetPassword(data) {
	return api.fetchData({
		url: '/api/auth/reset-password',
		method: 'post',
		data,
	});
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import appConfig from 'configs/app.config';

import en from './lang/en.json';
import es from './lang/es.json';

const resources = {
	en: {
		translation: en,
	},
	es: {
		translation: es,
	},
};

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: appConfig.locale,
	lng: appConfig.locale || 'es',
	interpolation: {
		escapeValue: false,
	},
});

export const dateLocales = {
	en: () => import('dayjs/locale/en'),
	es: () => import('dayjs/locale/es'),
};

export default i18n;
